import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useQuery } from '../../helpers/hooks';
import { getDatasetParameterByName, stringifyBytesSize } from '../../helpers';
import {
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Badge,
  Container,
  Card,
} from 'react-bootstrap';
import { getFilesByDatasetId } from '../../api/icat-plus/catalogue';
import { getDownloadURLByDatafileIds } from '../../api/icat-plus/ids';
import { useHistory } from 'react-router';
import ResponsiveTable from '../../components/Table/ResponsiveTable';
import Loader from '../../components/Loader';
import { trackDownload, trackH5Viewer } from '../../helpers/trackData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import ViewerFactory from './ViewerFactory';
import { isViewerAvailable, isZeroBytesFile } from './helper';

const ViewerButton = (props) => {
  const { selectDatafile, datafile, text } = props;

  return (
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip id={`tooltip-right`}>{text}</Tooltip>}
    >
      <Button
        size="sm"
        onClick={() => {
          trackH5Viewer(`${datafile.id} - ${datafile.name}`);
          selectDatafile(datafile, 'h5');
        }}
      >
        <FontAwesomeIcon icon={faEye} />
      </Button>
    </OverlayTrigger>
  );
};

/**
 * Returns the name of the dataset if it is processed or acquisition if the type is acquisition
 * This prevents that long names are displayed in a ugly manner
 * @param {*} dataset
 * @param {*} datafile
 */
const formatName = (dataset, datafile) => {
  const currentDataset = [dataset, ...dataset.outputDatasets].find((ds) => {
    if (ds) {
      return ds.id === datafile.dataset.id;
    }
    return null;
  });

  if (
    currentDataset &&
    currentDataset.type &&
    currentDataset.type === 'acquisition'
  ) {
    return (
      <Badge bg="light" text="dark">
        {currentDataset.type.toUpperCase()}
      </Badge>
    );
  }
  if (!currentDataset) return;
  return (
    <Badge bg="dark" text="light">
      {getDatasetParameterByName(currentDataset, 'datasetName').toUpperCase()}
    </Badge>
  );
};

function FileView(props) {
  const { dataset, sessionId } = props;
  const history = useHistory();

  const [data, setData] = useState([]);
  const [files, setFiles] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [search, setSearch] = useState('');
  const [sizePerPage, setSizePerPage] = useState(20);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const query = useQuery();
  const datafileId = query.get('datafileId');

  useEffect(() => {
    async function getData() {
      setFetching(true);

      if (!dataset.outputDatasets) {
        dataset.outputDatasets = [];
      }
      const res = await axios.get(
        `${getFilesByDatasetId(sessionId, [
          dataset.id,
          ...dataset.outputDatasets.map((ds) => ds.id),
        ])}?limit=${sizePerPage}&skip=${skip}&search=${search}`
      );
      const dataFilesList = res.data.map((row) => row.Datafile);
      setData(res.data);
      setFiles(dataFilesList);
      setFetching(false);
    }

    getData();
  }, [
    dataset.id,
    dataset.outputDatasets,
    search,
    sessionId,
    sizePerPage,
    skip,
  ]);

  const totalSize = data && data.length > 0 ? data[0].meta?.page?.total : 0;

  const selectDatafile = (datafile) => {
    query.set('datafileId', datafile.id);
    history.push({
      search: query.toString(),
    });
  };

  /**
   * This functions returns the view button in case the datafile is viewable
   * It means, HDF5 files and the images in the gallery so far
   * @param {*} _
   * @param {*} dataFile
   * @returns
   */
  const displayViewerButton = (_, dataFile) => {
    if (isViewerAvailable(dataFile) && !isZeroBytesFile(dataFile)) {
      return (
        <ViewerButton
          datafile={dataFile}
          selectDatafile={selectDatafile}
          text="Click to open Viewer"
        ></ViewerButton>
      );
    }
  };

  const downloadFormatter = (_, dataFile) => {
    if (!isZeroBytesFile(dataFile)) {
      return (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id={`tooltip-right`}>
              Click to download the file {stringifyBytesSize(dataFile.fileSize)}
            </Tooltip>
          }
        >
          <a
            href={getDownloadURLByDatafileIds(sessionId, dataFile.id)}
            onClick={() => trackDownload('DataFile', dataFile.id)}
          >
            <Button size="sm">
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </a>
        </OverlayTrigger>
      );
    }
  };

  function handleTableChange(page, sizePerPage) {
    setPage(parseInt(page || 1));
    setSizePerPage(parseInt(sizePerPage || 20));
    setSkip(sizePerPage * (page - 1));
  }

  function onSearch(s) {
    setSearch(s);
    setPage(1);
    setSkip(sizePerPage * (page - 1));
  }

  if (fetching) {
    return <Loader message="Loading files..." spacedOut />;
  }

  const selectedFile = files.find((df) => df.id.toString() === datafileId);
  return (
    <Card style={{ margin: 5 }}>
      <Card.Header>File Explorer</Card.Header>
      <Card.Body>
        <Container fluid>
          <Row style={{ margin: 10, fontSize: '0.9em' }}>
            <Col xs={12} md={4}>
              <ResponsiveTable
                striped={false}
                remote={true}
                data={files}
                columns={[
                  { dataField: 'id', text: '', hidden: true },
                  {
                    dataField: 'name',
                    text: '',
                    headerStyle: { width: '100px' },
                    formatter: (_, datafile) => {
                      return formatName(dataset, datafile);
                    },
                  },
                  {
                    dataField: 'location',
                    text: 'Location',
                    formatter: (_, datafile) => {
                      return datafile.name;
                    },
                  },
                  {
                    dataField: 'download',
                    text: '',
                    formatter: downloadFormatter,
                    headerStyle: { width: '60px' },
                  },
                  {
                    dataField: 'Preview',
                    text: '',
                    formatter: displayViewerButton,
                    headerStyle: { width: '70px' },
                  },
                ]}
                pageOptions={{
                  page: parseInt(page),
                  sizePerPage,
                  totalSize,
                  showTotal: true,
                  sizePerPageList: [
                    { text: '10', value: 10 },
                    { text: '20', value: 20 },
                    { text: '30', value: 30 },
                    { text: '50', value: 50 },
                  ],
                }}
                defaultSearchText={search}
                handleTableChange={handleTableChange}
                onSearch={onSearch}
              />
            </Col>
            <Col xs={12} md={8}>
              <ViewerFactory
                datafile={selectedFile}
                sessionId={sessionId}
                dataset={
                  selectedFile &&
                  [dataset, ...dataset.outputDatasets].find(
                    (ds) => ds.id === selectedFile.dataset.id
                  )
                }
              ></ViewerFactory>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

export default FileView;
