import { Resource } from 'rest-hooks';
import ICATPLUS from '../config/icatPlus';

export default class ExperimentalReportResource extends Resource {
  proposal = '';
  underEmbargo = true;
  nbReports = 0;
  reports = [];

  pk() {
    return `expReport-${this.proposal}`;
  }

  static get key() {
    return 'ExperimentalReportResource';
  }

  static url(params) {
    return this.listUrl(params);
  }

  static listUrl(params) {
    const { doi } = params;
    return `${ICATPLUS.server}/doi/${doi}/reports`;
  }
}
