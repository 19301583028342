import React from 'react';
import { Col, Container, Row, Alert } from 'react-bootstrap';
import moment from 'moment';
import {
  getDatasetParameterByPrefixName,
  getDatasetParameterValueByName,
  hasSampleNameChanged,
} from '../../../../helpers';
import ParameterTableWidget from '../../../Instrument/ParameterTableWidget';
import GalleryDatasetThumbnail from '../../GalleryDatasetThumbnail';
//import EMDatasetSummary from '../EM/EMDatasetSummary';
import MXDatasetSummary from '../../MXDatasetSummary';

const getParameters = (dataset) => {
  return [
    { key: 'Name', name: 'Name', value: dataset.name },
    {
      key: 'Definition',
      name: 'Technique',
      value: getDatasetParameterValueByName(dataset, 'definition'),
    },
    {
      key: 'Start',
      name: 'Start',
      value: moment(dataset.startDate).format('LTS'),
    },
    { key: 'Sample', name: 'Sample', value: dataset.sampleName },
    {
      key: 'Description',
      name: 'Description',
      value: getDatasetParameterValueByName(dataset, 'Sample_description'),
    },
  ];
};

function Summary(props) {
  const { dataset, sessionId } = props;

  const technique = getDatasetParameterValueByName(dataset, 'definition');

  if (
    technique !== 'EM' &&
    getDatasetParameterByPrefixName(dataset, 'MX_').length > 1
  ) {
    /** It is MX */
    return <MXDatasetSummary dataset={dataset} sessionId={sessionId} />;
  }

  const hasSampleChanged = hasSampleNameChanged(dataset);

  return (
    <Container fluid style={{ margin: 20 }}>
      <Row>
        <Col xs={12} md={3}>
          <ParameterTableWidget striped parameters={getParameters(dataset)} />
        </Col>
        <Col xs={12} sm={12} md={2}>
          <GalleryDatasetThumbnail dataset={dataset} index={0} />
        </Col>
        <Col xs={12} sm={12} md={2}>
          <GalleryDatasetThumbnail dataset={dataset} index={1} />
        </Col>
        <Col xs={12} sm={12} md={2}>
          <GalleryDatasetThumbnail dataset={dataset} index={2} />
        </Col>
        <Col xs={12} sm={12} md={2}>
          <GalleryDatasetThumbnail dataset={dataset} index={3} />
        </Col>
      </Row>
      {hasSampleChanged && (
        <Row>
          <Alert variant="warning">
            The sample name has been modified and the original location has
            changed. The files may not be displayed and downloaded correctly
            from the data portal. Please use Globus to download them.
          </Alert>
        </Row>
      )}
    </Container>
  );
}

export default Summary;
