import React from 'react';
import ParcelCondensedView from '../Parcels/ParcelCondensedView';
import {
  userPortalLinkFormatter,
  beamlineFormatter,
  startDateFormatter,
  nameFormatter,
} from '../Investigation/utils';
import styles from './ParcelSection.module.css';
import { STATUS_DEFS } from '../../constants/parcelStatuses';
import { itemsFormatter } from '../../helpers';
import UI from '../../config/ui';

function formatAddress(address) {
  return address ? (
    <>
      {address.name} {address.surname} <br />
      {address.companyName}
      <br />
      {address.address}
      <br />
      {(address.postalCode || address.city) && (
        <>
          {address.postalCode} {address.city}
        </>
      )}
      {address.country && <> ({address.country})</>}
    </>
  ) : (
    ''
  );
}

function formatArray(items) {
  return items.map((i) => <p>{i}</p>);
}

function formatLocalContacts(localContactNames) {
  return localContactNames ? formatArray(localContactNames) : '';
}

function ParcelColumns(hideAdminColumn) {
  return [
    { text: '', dataField: '_id', hidden: true, searchable: false },
    {
      dataField: 'condensedView',
      text: 'Parcel',
      isDummyField: true,
      formatter: (_, parcel) => {
        return <ParcelCondensedView parcel={parcel} />;
      },
      responsiveHeaderStyle: {
        xs: { width: '100%' },
        sm: { width: '100%' },
        md: { hidden: true },
        lg: { hidden: true },
      },
    },
    {
      text: 'Name',
      dataField: 'name',
      sort: true,
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
      },
    },
    {
      text: 'Proposal',
      dataField: 'experiment',
      formatter: (_, parcel) => {
        return nameFormatter(
          parcel.investigation
            ? parcel.investigation
            : { name: parcel.investigationName, id: parcel.investigationId },
          true
        );
      },
      sort: true,
      responsiveHeaderStyle: {
        xs: { width: 150, hidden: UI.userPortal.isLinkEnabled },
        sm: { width: 150, hidden: UI.userPortal.isLinkEnabled },
        md: { width: 150, hidden: UI.userPortal.isLinkEnabled },
        lg: { width: 150, hidden: UI.userPortal.isLinkEnabled },
      },
    },
    {
      text: 'A-Form',
      dataField: 'userPortal',
      width: 150,
      formatter: (_, parcel) =>
        userPortalLinkFormatter(parcel.investigation, 120),
      responsiveHeaderStyle: {
        xs: { width: 140, hidden: !UI.userPortal.isLinkEnabled },
        sm: { width: 140, hidden: !UI.userPortal.isLinkEnabled },
        md: { width: 140, hidden: !UI.userPortal.isLinkEnabled },
        lg: { width: 140, hidden: !UI.userPortal.isLinkEnabled },
      },
    },

    {
      text: 'Beamline',
      dataField: 'beamline',
      formatter: (_, parcel) => {
        return (
          <span style={{ fontWeight: 'bold' }}>
            {beamlineFormatter(parcel.investigation)}
          </span>
        );
      },
      onMatch: (searchText, _, parcel) => {
        if (!parcel.investigation) {
          return false;
        }
        const beamline = parcel.investigation?.instrument?.name;
        return !!beamline && beamline.toLowerCase().includes(searchText);
      },
      sort: true,
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
        md: { width: 100 },
        lg: { width: 100 },
      },
    },
    {
      text: 'Local Contact',
      dataField: 'localContactFullnames',
      formatter: (localContactNames) => {
        return formatLocalContacts(localContactNames);
      },
      sort: true,
      onMatch: (searchText, _, parcel) => {
        if (!parcel.localContactNames) {
          return false;
        }
        return (
          parcel.localContactNames.filter((l) =>
            l.toLowerCase().includes(searchText)
          ).length > 0
        );
      },
      hidden: hideAdminColumn,
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
      },
    },
    {
      text: 'Start date',
      dataField: 'startDate',
      formatter: (_, parcel) => {
        return parcel.investigation
          ? startDateFormatter(parcel.investigation)
          : '';
      },
      sort: true,
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
        md: { width: 110 },
        lg: { width: 110 },
      },
    },
    {
      text: 'Number of items',
      dataField: 'items',
      formatter: (items) => formatArray(itemsFormatter(items)),
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
        md: { width: 180 },
        lg: { width: 180 },
      },
    },
    {
      text: 'Status',
      dataField: 'status',
      formatter: (status) => STATUS_DEFS[status].label,
      sort: true,
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
      },
    },
    {
      text: 'Dangerous goods',
      dataField: 'containsDangerousGoods',
      sort: true,
      formatter: (containsDangerousGoods) =>
        containsDangerousGoods ? (
          <span className={styles.dangerous}>YES</span>
        ) : (
          'NO'
        ),
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
        md: { width: 150 },
        lg: { width: 150 },
      },
    },
    {
      text: 'After experiment',
      dataField: 'returnAddress',
      formatter: (returnAddress) =>
        returnAddress ? (
          'Ship back'
        ) : (
          <span className={styles.dangerous}>Destroy</span>
        ),
      sort: true,
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
        md: { width: 150 },
        lg: { width: 150 },
      },
    },
    {
      text: 'Sender Address ',
      dataField: 'shippingAddress',
      formatter: (shippingAddress) => formatAddress(shippingAddress),
      onMatch: (searchText, _, parcel) => {
        const addressFields = [
          parcel.shippingAddress.name,
          parcel.shippingAddress.surname,
          parcel.shippingAddress.companyName,
          parcel.shippingAddress.address,
          parcel.shippingAddress.postalCode,
          parcel.shippingAddress.city,
          parcel.shippingAddress.country,
        ];
        return (
          addressFields
            .filter((a) => a !== undefined && a !== null)
            .filter((a) => a.toLowerCase().includes(searchText)).length > 0
        );
      },
      sort: true,
      hidden: hideAdminColumn,
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
      },
    },
  ];
}

export default ParcelColumns;
