const UI = {
  status: {
    offline: {
      enabled: false,
      message: "Sorry, we're down for maintenance",
    },
  },
  projects: {
    humanOrgans: {
      frontEndURL: 'https://human-organ-atlas.esrf.eu/datasets/{datasetId}',
    },
    paleo: {
      frontEndURL: 'https://paleo.esrf.fr/datasets/{datasetId}',
    },
  },
  imageViewer: {
    fileExtensions: ['.png', '.jpg', '.jpeg'],
  },
  h5Viewer: {
    url: 'https://hibou.esrf.fr',
    /** Files with these extension will be open with the h5Viewer */
    fileExtensions: ['.hdf5', '.h5', '.nexus', '.nx', '.nxs', '.cxi'],
  },
  etherpad: {
    enabled: false,
    beamlines: ['ID01', 'ID09', 'ID10', 'ID31', 'ID32'],
    url: 'https://etherpad-logbook.esrf.fr/auth_session',

    //url: 'http://localhost:9001/auth_session',
    //url: 'http://dau-dm-03:9001/auth_session',
  },
  menu: {
    applicationTitle: 'Data Portal',
    isSearchVisible: false,
    isClosedDataVisible: true,
    isOpenDataVisible: true,
    isMySelectionVisible: true,
  },
  sampleTracking: {
    //** Makes visible or invisible the menus and tabs related to sample tracking */
    enabled: true,
  },
  loginForm: {
    header: '',
    /** Text displayed on top of the username sigin form. Someone can customize the text as: User office account, ESRF Account, Umbrella account, etc... */
    usernameLabel: 'Username',
    ssoBtnLabel: 'Sign in with ESRF SSO',
    accountCreationLink:
      'https://smis.esrf.fr/misapps/SMISWebClient/accountManager/searchExistingAccount.do?action=search',
    note: {
      enabled: true,
      title: 'Important note',
      notes: [
        {
          text:
            'In order to login to the Data Portal of the ESRF to send samples or browse embargoed data you need to be declared a member of a proposal on the ESRF User Portal. Once this is done your account will be activated <b>45 days</b> before the first experiment starts. If you need access earlier please contact the <a rel="noopener noreferrer" target="_blank" href="http://www.esrf.eu/UsersAndScience/UserGuide/Contacts" >ESRF User Office</a>.<br/>Anonymous login to browse open data is always possible.',
        },
        {
          text:
            'During 2019 and according to the General Data Protection Regulation, all portal users who did not consent to the <a href="http://www.esrf.fr/GDPR" rel="noopener noreferrer" target="_blank" > User Portal Privacy Statement</a> have had their account deactivated. Please contact the <a rel="noopener noreferrer" target="_blank" href="http://www.esrf.eu/UsersAndScience/UserGuide/Contacts" >User Office</a> if you wish to reactivate it.',
        },
      ],
    },
  },
  userPortal: {
    isLinkEnabled: true,
    /** This is the name of the investigationParameter where it is stored the pk of the investigation in the User Portal   */
    investigationParameterPkName: 'Id',
    link:
      'https://smis.esrf.fr/misapps/SMISWebClient/protected/aform/manageAForm.do?action=view&expSessionVO.pk=',
  },
  analysis: {
    jupyter: {
      isLinkEnabled: true,
      link: 'https://jupyter-slurm.esrf.fr/hub/spawn?partition=jupyter-nice',
    },
  },
  investigationContainer: {
    isDatasetListVisible: true,
  },
  searchDelay: 2300,
  logbook: {
    /** Number of logbook events to display per page. EVENTS_PER_PAGE should be lower than EVENTS_PER_DOWNLOAD */
    EVENTS_PER_PAGE: 100,
    /* the field used to sort events. Most of the time 'creationDate' is used. Possible values: 'creationDate', '_id', 'createdAt', 'updatedAt' */
    SORT_EVENTS_BY: '_id',
    /* the order the events sorted by SORT_EVENTS_BY will be ordered. Possible values: 1 (for ascending order), -1 (for descending order)*/
    SORTING_ORDER: -1,
    /** Tinymce editor minimum height when there is a single editor in the modal */
    SINGLE_EDITOR_MIN_HEIGHT: '200px',
    /** Tinymce editor maximum height when there is a single editor in the modal */
    SINGLE_EDITOR_MAX_HEIGHT: '808px',
    /** Tinymce editor minimum height when there are 2 editors in the modal */
    DUAL_EDITOR_MIN_HEIGHT: '270px',
    /** Tinymce editor maximum height when there are 2 editors in the modal */
    DUAL_EDITOR_MAX_HEIGHT: '270px',
    /* Default tag color used in the logbook when tag color is not set */
    DEFAULT_TAG_COLOR: '#a6bded',
    /* Refresh event list periodically with a time interval defined by AUTOREFRESH_DELAY when new events arrived.
     * When it is set to false, the event list is not refreshed but the amount of new events which arriived is shown.*/
    AUTOREFRESH_EVENTLIST: false,
    /* Time interval between requests checking whether new events arrived. (in milliseconds) */
    AUTOREFRESH_DELAY: 60000,
  },
  dangerousGoodsHelp:
    'This question is for ESRF internal use only - you must correctly declare your parcel in the official transport documents',
  footer: {
    text: 'European Synchrotron Radiation Facility',
    link: 'https://www.esrf.fr/',
    certification: {
      enabled: true,
      logo: '/images/CoreTrustSeal-logo-transparent.png',
      alt: 'CoreTrustSeal',
      url: 'https://www.coretrustseal.org/',
    },
  },
  sample: {
    pageTemplateURL:
      'https://smis.esrf.fr/misapps/SMISWebClient/protected/samplesheet/view.do?pk=',
    editable: true,
    descriptionParameterName: 'Sample_description',
    nonEditableParameterName: 'Id',
  },
  welcomeNote: {
    enabled: false,
    title: "There's a new version of the data portal",
    body:
      'Exciting News! A new version of the data portal is now available. Click the button below to check it out and experience the latest features and improvements.',
    button: {
      url: 'https://data2.esrf.fr',
      label: 'Try it now!',
    },
  },
  welcomeAnnoucements: [
    {
      img: '/images/carousel/dataset.png',
      alt: 'data portal',
      captionTitle: 'Find, browse and download your data',
      captionText:
        "Access to your experiment's data and metedata, acccess to open data",
    },
    {
      img: '/images/carousel/DataPortalFileViewer.png',
      alt: 'h5Web',
      captionTitle: 'Visualize your data with H5Web',
      captionText:
        'Visualize your HDF5 files from the data portal with H5Web (Hibou)',
    },
    {
      img: '/images/carousel/doi.png',
      alt: 'doi',
      captionTitle: 'Cite Data DOIs',
      captionText:
        'Mint your Data DOI <a href="https://youtu.be/dPeN855-Mu4" target="_blank">how to mint a DOI</a>',
    },
    {
      img: '/images/carousel/logbook.png',
      alt: 'logbook',
      captionTitle: 'Electronic logbook',
      captionText:
        'Keep track of the experiment, so the data and metadata can be better understood and reused',
    },
  ],
  feedback: {
    email: 'dataportalrequests@esrf.fr',
    subject: 'Feedback',
  },
  globus: {
    enabled: true,
    url: 'https://app.globus.org/file-manager?',
    collections: [
      {
        root: '/data/visitor/',
        origin: '/data',
        originId: 'bfc3eff4-f5ca-4b4d-8532-e9a155f3613f',
      },
      {
        root: '/data/projects/hop',
        origin: '/data/projects/hop',
        originId: '340dc883-4b0d-476c-abb0-969e1ddd9dc0',
      },
      {
        root: '/data/projects/open-datasets',
        origin: '/data/projects/open-datasets/',
        originId: '8cbe8cdc-048a-48cf-b8a0-046a6af3ba44',
      },
      {
        root: '/data/projects/paleo',
        origin: '/data/projects/paleo/public',
        originId: 'e8fb6c4b-9ab0-4a1c-a79d-d51cef0b8c3d',
      },
    ],
  },
  doi: {
    prefix: '10.15151',
    publisher: {
      name: 'European Synchrotron Radiation Facility',
      url: 'https://www.esrf.fr',
    },
    server: 'https://api.datacite.org/dois',
    formatCitation: {
      server: 'https://citation.crosscite.org/format?style=apa&lang=en-US',
    },
    experimentalReport: {
      enabled: true,
      widget: 'ESRFExperimentalReportWidget',
      server: 'https://smis.esrf.fr/misapps/SMISServer/rest/reports/fileNames',
      ftp: 'http://ftp.esrf.fr/pub/UserReports',
    },
    openCitation: {
      server: 'https://opencitations.net/index/coci/api/v1/metadata',
      accessToken: 'd0633119-deff-4378-a192-8fb780c0bd8a ',
    },
  },
  landingPage: [
    {
      title: 'About the portal',
      texts: [
        {
          text:
            'This page centralizes the<a href="https://www.esrf.fr/DOI" target="_blank" rel="noopener noreferrer"> DOI</a> services offered by the ESRF to access data stored at the facility as part of its data policy. Data referred to by ESRF DOIs are accessed via the ESRF data catalogue <a href="https://data.esrf.fr" target="_blank" rel="noopener noreferrer">data.esrf.fr.</a>',
        },
        {
          text:
            'You can mint a DOI for a subset of data:<a href="https://youtu.be/dPeN855-Mu4" target="_blank" rel="noopener noreferrer">How to mint a DOI</a>',
        },
        {
          text:
            'Please contact us:  <a href="mailTo:dataportal-feedback@esrf.fr">dataportal-feedback@esrf.fr',
        },
      ],
      footer:
        'Access to data is governed by the <a href="http://www.esrf.eu/files/live/sites/www/files/about/organisation/ESRF%20data%20policy-web.pdf" target="_blank" rel="noopener noreferrer" >ESRF data policy</a>',
    },
    {
      title: 'DOI Resolver',
      texts: [
        {
          text:
            ' The search engine below only searches DOIs which have been minted at the ESRF.',
        },
        {
          text:
            ' Use <a href="https://doi.org" target="_blank" rel="noopener noreferrer"> doi.org</a> search engine for other DOIs.',
        },
        {
          text:
            '<a href="https://search.datacite.org/works?query=10.15151%2F*" target="_blank" rel="noopener noreferrer">List all ESRF DOIs</a>',
        },
      ],
      search: {
        placeHolder: '10.15151/ESRF-ES-90632078',
        error: 'Invalid ESRF DOI',
      },
    },
    {
      title: 'About DOI',
      texts: [
        {
          text:
            ' The Digital Object Identifier ( <a href="http://www.esrf.eu/home/UsersAndScience/UserGuide/esrf-data-policy/doi.html" target="_blank" rel="noopener noreferrer">DOI </a>) is a code composed of digits and characters. It identifies data in a unique and permanent way thereby allowing data to be referenced worldwide. The DOI has two parts, namely the DOI prefix and the DOI suffix separated by a slash ("/"). The ESRF prefix is 10.15151 followed by a slash and the ESRF suffix. Currently the following suffix(es) are defined:<ul> <li><pre>ESRF-ES - for a session at the ESRF</pre> </li><li><pre>ESRF-DC - for a subset of data at the ESRF</pre></li></ul>',
        },
        {
          text:
            'Refer to the DOI <a href="http://www.esrf.eu/home/UsersAndScience/UserGuide/esrf-data-policy/doi/doi---concepts.html" target="_blank" rel="noopener noreferrer">concepts page</a> to find out what a session is.',
        },
      ],
    },
  ],
  dmp: {
    enabled: true,
    server: 'https://dmp.esrf.fr',
  },
};

export default UI;
