import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import styles from './Menu.module.css';
import { useSelector } from 'react-redux';
import {
  faBook,
  faCalendar,
  faChartBar,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';

function ManagerMenu() {
  const { isAdministrator } = useSelector((state) => state.user);
  if (!isAdministrator) return null;
  return (
    <NavDropdown
      title="Manager"
      id="basic-nav-dropdown"
      className={styles.managerMenu}
    >
      <LinkContainer to="/calendar">
        <NavDropdown.Item>
          <FontAwesomeIcon icon={faCalendar} />
          <span style={{ marginLeft: 10 }}>Beamline Schedule</span>
        </NavDropdown.Item>
      </LinkContainer>

      <NavDropdown.Divider />

      <LinkContainer to="/manager/stats/data">
        <NavDropdown.Item>
          <FontAwesomeIcon icon={faChartBar} />
          <span style={{ marginLeft: 10 }}>Data Statistics</span>
        </NavDropdown.Item>
      </LinkContainer>

      <LinkContainer to="/manager/stats/sampletracking">
        <NavDropdown.Item>
          <FontAwesomeIcon icon={faEnvelope} />
          <span style={{ marginLeft: 10 }}>Sample Tracking Statistics</span>
        </NavDropdown.Item>
      </LinkContainer>

      <LinkContainer to="/manager/stats/logbook">
        <NavDropdown.Item>
          <FontAwesomeIcon icon={faBook} />
          <span style={{ marginLeft: 10 }}>Logbook Statistics</span>
        </NavDropdown.Item>
      </LinkContainer>

      <NavDropdown.Divider />

      <LinkContainer to="/logbook">
        <NavDropdown.Item>
          <FontAwesomeIcon icon={faBook} />
          <span style={{ marginLeft: 10 }}>ESRF Logbook</span>
        </NavDropdown.Item>
      </LinkContainer>
    </NavDropdown>
  );
}

export default ManagerMenu;
