const ICAT = {
  authentication: {
    /**
     * Automatic refresh
     * true - token will be refreshed automatically a (validityTokenPercentage * remainingMinutes) time
     * false - token will not be refresehd and user will be logged out automatically
     * */
    autoRefresh: false,
    // This is the percentage of validity of the token before refreshing (autoRefresh needs to be true)
    validityTokenPercentage: 0.9,

    // Single sign-on configuration
    sso: {
      enabled: !!+process.env.REACT_APP_SSO_AUTH_ENABLED,
      plugin: 'esrf',
      // This will refresh the SSO token when expired
      refreshToken: false,
      // App will logout if SSO session is closed
      handleSSOlogout: true,
      // Keycloak configuration - https://www.keycloak.org/docs/latest/securing_apps/#_javascript_adapter
      configuration: {
        realm: 'ESRF',
        url: 'https://websso.esrf.fr/auth/',
        clientId: 'icat',
      },
    },
    // Anonymous user's credentials
    anonymous: {
      enabled: !!+process.env.REACT_APP_ANONYMOUS_AUTH_ENABLED,
      plugin: 'db',
      username: 'reader',
      password: 'reader',
    },
    authenticators: [
      // Database authenticator
      {
        plugin: 'db',
        title: 'Database',
        enabled: !!+process.env.REACT_APP_DB_AUTH_ENABLED,
        message:
          'ESRF, CRG staff or long term visitors, please sign in with ESRF SSO',
      },
    ],
  },
};

export default ICAT;
