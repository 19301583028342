import {
  faDownload,
  faSyncAlt,
  faTasks,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { CancelToken } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import {
  getDatasetStatus,
  getRestoreURLByDatasetId,
  getDownloadURLByDatasetIds,
} from '../../api/icat-plus/ids';
import { trackDownload, trackRestore } from '../../helpers/trackData';
import DatasetRestoreModal from './DatasetRestoreModal';
import UI from '../../config/ui';
import { store } from '../../store';

export default function DatasetDownloadButton(props) {
  const { id, dataset } = props;
  const location = dataset.restoredLocation
    ? dataset.restoredLocation
    : dataset.location;

  const { sessionId } = store.getState().user;

  const [status, setStatus] = useState('FETCHING');
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);

  const handleCloseRestoreModal = () => setShowRestoreModal(false);
  const handleShowRestoreModal = () => setShowRestoreModal(true);

  let tokenSource;

  const getStatus = useCallback(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    tokenSource = CancelToken.source();

    axios
      .get(getDatasetStatus(sessionId, id), {
        cancelToken: tokenSource.token,
      })
      .then((response) => {
        setStatus(response.data[0]);
        setFetching(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          tokenSource = undefined;
          return;
        }

        setStatus(`Error: ${id}`);
        setFetching(false);
        setError(true);
      });
  }, [id, sessionId]);

  useEffect(() => {
    setFetching(true);
    getStatus();
  }, [getStatus]);

  const restore = () => {
    trackRestore(id);
    handleShowRestoreModal();
  };
  const handleSubmitRestoreModal = (name, email) => {
    setShowRestoreModal(false);
    axios
      .post(getRestoreURLByDatasetId(sessionId, id), { name, email })
      .finally(() => {
        getStatus();
      });
  };

  useEffect(() => {
    return () => {
      if (tokenSource) {
        tokenSource.cancel();
      }
    };
  }, [tokenSource]);

  const download = () => {
    trackDownload('Dataset', id);
  };

  const downloadURL = getDownloadURLByDatasetIds(sessionId, id);

  const getGlobusDownloadLink = () => {
    let url;
    if (UI.globus.enabled && location) {
      UI.globus.collections.forEach((collection) => {
        if (location.startsWith(collection.root)) {
          const globusPath = location.replace(collection.origin, '');
          const params = new URLSearchParams({
            origin_id: collection.originId,
            origin_path: globusPath,
          });
          url = `${UI.globus.url}${params.toString()}`;
        }
      });
    }
    return url;
  };

  if (error) {
    return (
      <p className="text-danger">
        <FontAwesomeIcon icon={faTimesCircle} /> Not available
      </p>
    );
  }

  if (status === 'ONLINE') {
    const globusURL = getGlobusDownloadLink();
    if (globusURL === undefined) {
      return (
        <a
          style={{ color: 'black' }}
          href={downloadURL}
          onClick={(e) => download(e)}
        >
          <Button variant="primary" size="sm">
            <FontAwesomeIcon icon={faDownload} style={{ marginRight: 5 }} />
            Download
          </Button>
        </a>
      );
    }
    return (
      <Dropdown id="dropdown-download-button" size="sm">
        <Dropdown.Toggle size="sm">
          <FontAwesomeIcon icon={faDownload} style={{ marginRight: 5 }} />
          Download
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href={downloadURL} onClick={(e) => download(e)}>
            Https (&#60;2GB)
          </Dropdown.Item>
          <Dropdown.Item target="_blank" href={globusURL}>
            Globus
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  if (status === 'RESTORING') {
    return (
      <a style={{ color: 'black' }} href={downloadURL}>
        <Button variant="dark" size="sm">
          <FontAwesomeIcon size="sm" spin icon={faSyncAlt} />
          <span style={{ color: 'black', marginLeft: 10 }}>Restoring</span>
        </Button>
      </a>
    );
  }

  if (status === 'ARCHIVED') {
    return (
      <>
        <DatasetRestoreModal
          show={showRestoreModal}
          onClose={handleCloseRestoreModal}
          onSubmit={handleSubmitRestoreModal}
        />
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={(e) => {
            restore(e);
          }}
        >
          <FontAwesomeIcon icon={faTasks} style={{ color: 'black' }} />
          <span style={{ color: 'black', marginLeft: 10 }}>Restore</span>
        </Button>
      </>
    );
  }

  if (fetching || status === 'FETCHING') {
    return <></>;
  }

  return <span>{status}</span>;
}
