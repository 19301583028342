import React from 'react';
import { Card, Stack } from 'react-bootstrap';
import { ExperimentalReportButton } from './ExperimentalReportButton';
import { useResource } from 'rest-hooks';
import ExperimentalReportResource from '../../resources/reports';

function getReportText(dataReport) {
  const underEmbargo = dataReport.underEmbargo;
  const hasMultipleReports = dataReport.nbReports > 1;
  const hasNoReport = dataReport.nbReports === 0;
  const esrfUserPortal =
    '<a href="https://smis.esrf.fr" target="_blank" rel="noopener noreferrer">ESRF User Portal.</a>';
  if (hasNoReport) {
    return `No report was found for ${dataReport.proposal}. Proposers and session participants may submit it via the ${esrfUserPortal}`;
  }
  if (underEmbargo) {
    return hasMultipleReports
      ? `The reports for ${dataReport.proposal} are confidential until the end of the data embargo period. Until then, they are only accessible to the proposers and session participants via the ${esrfUserPortal}`
      : `The report for ${dataReport.proposal} is confidential until the end of the data embargo period. Until then, it is only accessible to the proposers and session participants via the ${esrfUserPortal}`;
  }
  return hasMultipleReports
    ? `In total,<b> ${dataReport.nbReports}</b> reports have been found for ${dataReport.proposal}.`
    : `<b>One</b> report has been found for ${dataReport.proposal}.`;
}

export function ESRFExperimentalReportWidget(props) {
  const { doi } = props;

  const dataReports = useResource(ExperimentalReportResource.listShape(), {
    doi,
  });

  if (dataReports.length === 0) {
    return null;
  }

  return (
    <>
      <Card style={{ marginBottom: 12 }}>
        <Card.Header>Experimental Report</Card.Header>
        <Card.Body>
          {dataReports.map((dataReport, index) => (
            <Card.Text key={`card-report-${index}`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: getReportText(dataReport),
                }}
              />
              {!dataReport.underEmbargo && (
                <Stack
                  className="col-md-4"
                  gap={3}
                  style={{ marginTop: '4px' }}
                >
                  {dataReport.reports.map((report, idR) => (
                    <ExperimentalReportButton
                      key={`report-${index}-${idR}`}
                      reportName={report}
                    />
                  ))}
                </Stack>
              )}
            </Card.Text>
          ))}
        </Card.Body>
      </Card>
    </>
  );
}
