import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { DOITypeBadgeWidget } from './DOITypeBadgeWidget';
import { MetadataWidget } from './MetadataWidget';
import { ExperimentalReportWidget } from './ExperimentalReportWidget';
import { ExperimentalDataWidget } from './ExperimentalDataWidget';
import { ReferenceWidget } from './ReferenceWidget';
import LoadingBoundary from '../LoadingBoundary';
import {
  getAccessibilityStatus,
  getResourceType,
  getSessionDate,
} from './utilsDoiExtractor';
import { TitleWidget } from './TitleWidget';
import { AbstractWidget } from './AbstractWidget';
import { CitationWidget } from './CitationWidget';
import UI from '../../config/ui';
import { LinkedDOIWidget } from './LinkedDOIWidget';
import { UsersWidget } from './UsersWidget';

export function LandingPageWidget(props) {
  const { doiData } = props;

  const resourceType = getResourceType(doiData?.types);
  const sessionDate = getSessionDate(doiData.dates);
  const accessibilityStatus = getAccessibilityStatus(
    resourceType,
    sessionDate,
    doiData.publicationYear
  );
  const doi = doiData.doi.toUpperCase();

  return (
    <>
      <Container>
        <Row>
          <Col>
            <DOITypeBadgeWidget
              types={doiData.types}
              accessibilityStatus={accessibilityStatus}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Container fluid style={{ padding: 0 }}>
              <Row>
                <Col>
                  <TitleWidget titles={doiData.titles} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <UsersWidget users={doiData.creators} />
                </Col>
              </Row>
              {doiData.contributors && doiData.contributors.length > 0 && (
                <Row>
                  <Col>
                    <UsersWidget
                      users={doiData.contributors}
                      title="Contributors"
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <AbstractWidget descriptions={doiData.descriptions} />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <ExperimentalDataWidget
                    doi={doi}
                    accessibilityStatus={accessibilityStatus}
                    publicationYear={doiData.publicationYear}
                    resourceType={resourceType}
                  />
                </Col>
                {UI.doi.experimentalReport.enabled && (
                  <Col xs={12} md={6}>
                    <LoadingBoundary message="Loading reports..." spacedOut>
                      <ExperimentalReportWidget doi={doi} />
                    </LoadingBoundary>
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <LoadingBoundary message="Loading citation..." spacedOut>
                    <ReferenceWidget doi={doi} />
                  </LoadingBoundary>
                </Col>
              </Row>
              <Row>
                <Col>
                  <LoadingBoundary message="Loading citations..." spacedOut>
                    <CitationWidget doi={doi} />
                  </LoadingBoundary>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={4}>
            <Container fluid style={{ padding: 0 }}>
              <Row>
                <Col>
                  <LoadingBoundary message="Loading instruments..." spacedOut>
                    <MetadataWidget
                      doi={doi}
                      resourceType={resourceType}
                      subjects={doiData.subjects}
                      sessionDate={sessionDate}
                      publicationYear={doiData.publicationYear}
                    />
                  </LoadingBoundary>
                </Col>
              </Row>
              <Row>
                <Col>
                  <LinkedDOIWidget
                    relatedIdentifiers={doiData.relatedIdentifiers}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}
