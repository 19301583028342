import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import TabContainerMenu from '../components/TabContainerMenu/TabContainerMenu';
import PageNotFound from './PageNotFound';
import { useResource } from 'rest-hooks';
import InvestigationResource from '../resources/investigation';
import ParticipantsPanel from '../components/Investigation/ParticipantsPanel';
import ProposalPanel from '../components/Investigation/ProposalPanel';
import { usePageTracking } from '../helpers/hooks';

function InvestigationProposalPage() {
  usePageTracking();
  const { investigationId } = useParams();
  const user = useSelector((state) => state.user);

  const investigations = useResource(InvestigationResource.listShape(), {
    ids: investigationId,
  });
  const investigation =
    investigations.length > 0 ? investigations[0] : undefined;

  if (!investigation) {
    return <PageNotFound />;
  }

  return (
    <Container fluid>
      <Row>
        <Col sm={12}>
          <TabContainerMenu investigation={investigation} />
          <div style={{ margin: 20 }}>
            <ProposalPanel investigation={investigation} />
          </div>
          <div style={{ margin: 20 }}>
            <ParticipantsPanel
              investigationId={investigationId}
              name={user.name}
              instrument={investigation.instrument}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default InvestigationProposalPage;
