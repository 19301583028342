import React, { useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setBreadCrumbs } from '../../actions/breadcrumbs';
import DataCollectionTable from '../../components/DataCollection/DataCollectionTable';
import LoadingBoundary from '../../components/LoadingBoundary';
import ReleasedInvestigationsTable from './ReleasedInvestigationsTable';
import { OPEN_DATA_PATH } from '../../constants/routePaths';
import { usePageTracking } from '../../helpers/hooks';

const dataTab = (title, eventKey, child) => {
  return (
    <Tab style={{ margin: 10 }} eventKey={title} title={title}>
      <LoadingBoundary inPanel message="Loading data...">
        {child}
      </LoadingBoundary>
    </Tab>
  );
};
function OpenDataPage() {
  usePageTracking();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadCrumbs([{ name: 'Open Data', link: OPEN_DATA_PATH }]));
  }, [dispatch]);

  return (
    <div className="app__inner">
      <Tabs
        defaultActiveKey="Investigations"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        {dataTab('Investigations', 0, <ReleasedInvestigationsTable />)}
        {dataTab(
          'Data collections',
          1,
          <DataCollectionTable loadCollections={true} />
        )}
        {dataTab(
          'Documents',
          2,
          <DataCollectionTable loadCollections={false} />
        )}
      </Tabs>
    </div>
  );
}

export default OpenDataPage;
