import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import Footer from './components/Footer';
import UI from './config/ui';
import AddressesPage from './containers/AddressesPage';
import LoginPage from './containers/LoginPage';
import MyDataPage from './containers/MyData/MyDataPage';
import MyParcelsPage from './containers/MyParcelsPage';
import ParcelPage from './containers/ParcelPage';
import ShippingPage from './containers/ShippingPage';
import OfflinePage from './containers/OfflinePage';
import DOIPage from './containers/DOIPage';
import UserManagementPage from './containers/UserManagementPage';
import CalendarPage from './containers/Calendar/CalendarPage';
import SampleTrackingStatsPage from './containers/Stats/SampleTracking/SampleTrackingStatsPage';
import SearchPage from './containers/SearchPage';
import OpenDataPage from './containers/OpenData/OpenDataPage';
import ClosedDataPage from './containers/ClosedData/ClosedDataPage';
import InvestigationProposalPage from './containers/InvestigationProposalPage';
import DatasetsPage from './containers/DatasetsPage';
import DatasetPage from './containers/DatasetPage';
import TagPage from './containers/TagPage';
import LogbookPage from './containers/LogbookPage';
import SelectionPage from './containers/Selection/SelectionPage';
import CameraPage from './containers/CameraPage';
import DataStatisticsPage from './containers/DataStatistics/DataStatisticsPage';
import LogbookStatisticsPage from './containers/LogbookStatsPage';
import MintSelectionPage from './containers/Selection/MintSelectionPage';
import BeamlineDataPage from './containers/BeamlineData/BeamlineDataPage';
import Menu from './components/Menu/Menu';
import { useQuery } from './helpers/hooks';
import PageNotFound from './containers/PageNotFound';
import LoadingBoundary from './components/LoadingBoundary';
import CookieConsent from 'react-cookie-consent';
import {
  CLOSED_DATA_PATH,
  MY_DATA_PATH,
  OPEN_DATA_PATH,
  BEAMLINE_PATH,
  CAMERA_PATH,
  WELCOME_PATH,
  LANDING_PAGE_PATH,
} from './constants/routePaths';
import WelcomePage from './containers/WelcomePage';
import WelcomeLandingPage from './containers/WelcomeLandingPage';
import DOILandingPage from './containers/DOILandingPage';
import InvestigationSamplesPage from './containers/InvestigationSamplesPage';
import { handleToken } from './actions/login';

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { sessionId, refreshTime } = user;
  const location = useLocation();
  const query = useQuery();
  const toParam = query.has('to')
    ? decodeURIComponent(query.get('to'))
    : undefined;

  if (UI.status.offline.enabled) {
    return <OfflinePage />;
  }

  dispatch(handleToken(sessionId, refreshTime));

  /** if session expired or not session then logout */
  if (!user.sessionId) {
    return (
      <Switch>
        <Route exact path={LANDING_PAGE_PATH} component={WelcomeLandingPage} />
        <Route exact path={`${LANDING_PAGE_PATH}/:prefix/:suffix`}>
          <LoadingBoundary message="Loading doi..." spacedOut>
            <DOILandingPage />
          </LoadingBoundary>
        </Route>

        <Route path="/login">
          <div className="app">
            <Menu />
            <LoginPage />
          </div>
          <Footer />
          <CookieConsent>
            The ESRF Data portal uses cookies. These cookies are used to enhance
            the user experience and allow us to remember you.
          </CookieConsent>
        </Route>
        <Route>
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        </Route>
      </Switch>
    );
  }

  const isSampleTrackingEnabled =
    UI.sampleTracking.enabled && !user.isAnonymous;

  return (
    <Switch>
      <Route path={CAMERA_PATH} component={CameraPage} />
      <Route exact path={LANDING_PAGE_PATH} component={WelcomeLandingPage} />
      <Route exact path={`${LANDING_PAGE_PATH}/:prefix/:suffix`}>
        <LoadingBoundary message="Loading doi..." spacedOut>
          <DOILandingPage />
        </LoadingBoundary>
      </Route>

      <Route path="/">
        <div className="app">
          <Menu />

          <Switch>
            <Route exact path={['/', '/home', MY_DATA_PATH]}>
              <MyDataPage />
            </Route>

            <Route exact path={WELCOME_PATH} component={WelcomePage} />

            <Route exact path="/search" component={SearchPage} />
            <Route exact path="/usermanagement">
              <LoadingBoundary
                message="Loading instrument scientists..."
                spacedOut
              >
                <UserManagementPage />
              </LoadingBoundary>
            </Route>

            {user.isAdministrator && (
              <Route exact path="/calendar">
                <CalendarPage />
              </Route>
            )}

            {user.isAdministrator && isSampleTrackingEnabled && (
              <Route exact path="/manager/stats/sampletracking">
                <SampleTrackingStatsPage component={SampleTrackingStatsPage} />
              </Route>
            )}

            <Route exact path={OPEN_DATA_PATH} component={OpenDataPage} />
            <Route exact path="/public/:prefix/:suffix" component={DOIPage} />

            <Route exact path={CLOSED_DATA_PATH} component={ClosedDataPage} />
            <Route
              exact
              path={`${BEAMLINE_PATH}:name`}
              component={BeamlineDataPage}
            />

            {isSampleTrackingEnabled && (
              <Route exact path="/parcels" component={MyParcelsPage} />
            )}
            {isSampleTrackingEnabled && (
              <Route exact path="/addresses" component={AddressesPage} />
            )}

            <Route exact path="/beamline/:instrumentName/events">
              <LoadingBoundary message="Loading logbook..." spacedOut>
                <LogbookPage />
              </LoadingBoundary>
            </Route>

            <Route exact path="/logbook">
              <LoadingBoundary message="Loading logbook..." spacedOut>
                <LogbookPage />
              </LoadingBoundary>
            </Route>

            <Route path="/tag">
              <LoadingBoundary message="Loading tags..." spacedOut>
                <TagPage />
              </LoadingBoundary>
            </Route>

            <Route exact path="/dataset/:datasetId">
              <LoadingBoundary message="Loading dataset..." spacedOut>
                <DatasetPage />
              </LoadingBoundary>
            </Route>

            <Route path="/investigation">
              <LoadingBoundary message="Loading investigation..." spacedOut>
                <Route
                  path="/investigation/:investigationId/datasets"
                  render={({ match }) =>
                    UI.investigationContainer.isDatasetListVisible ? (
                      <DatasetsPage />
                    ) : (
                      <Redirect
                        to={`/investigation/${match.params.investigationId}/events`}
                      />
                    )
                  }
                />

                <Route exact path="/investigation/:investigationId/events">
                  <LogbookPage />
                </Route>

                {isSampleTrackingEnabled && (
                  <Route path="/investigation/:investigationId/shipping">
                    <ShippingPage />
                  </Route>
                )}

                <Route path="/investigation/:investigationId/parcel/:parcelId">
                  <ParcelPage />
                </Route>

                <Route path="/investigation/:investigationId/proposal">
                  <InvestigationProposalPage />
                </Route>
                <Route path="/investigation/:investigationId/samples">
                  <InvestigationSamplesPage />
                </Route>
              </LoadingBoundary>
            </Route>

            <Route exact path="/selection">
              <SelectionPage />
            </Route>

            <Route exact path="/selection/mint">
              <MintSelectionPage />
            </Route>

            {user.isAdministrator && (
              <Route exact path="/manager/stats/data">
                <DataStatisticsPage />
              </Route>
            )}

            {user.isAdministrator && (
              <Route exact path="/manager/stats/logbook">
                <LogbookStatisticsPage />
              </Route>
            )}

            <Route exact path="/login">
              <Redirect
                to={
                  // Redirect users back to where they were before logging in:
                  // - for form or anonymous login, look in location state (cf. `/login` redirect above)
                  // - for SSO login, look in `to` query param (cf. `redirectUri` option in `src/components/SSO.js`)
                  location.state?.from ?? toParam ?? '/'
                }
              />
            </Route>

            <Route>
              <PageNotFound />
            </Route>
          </Switch>
        </div>
        <Footer />
      </Route>
    </Switch>
  );
}

export default App;
