import { Resource } from 'rest-hooks';
import { store } from '../store';
import { getDataCollections } from '../api/icat-plus/catalogue';

export default class DataCollectionResource extends Resource {
  id = undefined;
  doi = '';
  dataCollectionDatasets = [];
  dataPublications = [];
  parameters = [];

  pk() {
    return this.id?.toString();
  }
  static get key() {
    return 'DataCollectionResource';
  }

  // eslint-disable-next-line no-unused-vars
  static listUrl() {
    const { sessionId } = store.getState().user;
    return getDataCollections(sessionId);
  }
  static url(params) {
    return this.listUrl(params);
  }
}
