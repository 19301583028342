import {
  BREAKPOINTS,
  BYTE_UNITS,
  ITEM_TYPES,
  ITEM_TYPE_DEFS,
} from '../constants';

import uiConfig from '../config/ui';
import PARAMETERS from '../config/parameters';

export const RESOURCES_GALLERY_FILE_PATHS_PARAMETER =
  'ResourcesGalleryFilePaths';

export const RESOURCES_GALLERY_PARAMETER = 'ResourcesGallery';

export function getCurrentBreakpoint() {
  const width = window.innerWidth;
  return width
    ? Object.entries(BREAKPOINTS).find(([, w]) => width < w)[0]
    : 'md';
}

export function getDatasetParameterByName(dataset, parameterName) {
  const parameter = dataset.parameters.find((o) => o.name === parameterName);
  if (parameter && parameter.value) {
    return parameter.value;
  }
}

export function getDatasetParameterByPrefixName(dataset, prefix) {
  const parameters = JSON.parse(JSON.stringify(dataset.parameters));
  return parameters
    .filter((p) => p.name.startsWith(prefix))
    .map((p) => ({ ...p, name: PARAMETERS[p.name] || p.name }));
}

/**
 * This method returns the value of the dataset parameter with its units if is not NA otherwise it returns ''
 * @param {*} dataset
 * @param {*} name
 */
export function getDatasetParameterValueByName(dataset, name) {
  const { parameters } = dataset;
  const sampleNameParameter = parameters.filter((o) => o.name === name);
  if (sampleNameParameter.length > 0) {
    const { value } = sampleNameParameter[0];
    return value;
  }
  return '';
}

/**
 * Returns the dataset that is output of the parameter dataset which name corresponds to outputDatasetName
 * @param {*} dataset
 * @param {*} outputDatasetName
 * @returns outputdataset
 */
export function getOutputDatasetByName(dataset, outputDatasetName) {
  if (dataset.outputDatasets) {
    return dataset.outputDatasets.find(
      (ds) =>
        ds.parameters.find((p) => p.name === 'datasetName').value ===
        outputDatasetName
    );
  }
}

/**
 * This method will return the imageId of the gallery which file contains the key
 * @param {*} dataset
 * @param {*} outputDatasetName undefined is the gallery is in the raw dataset
 * @param {*} key
 */
export function getOutputGalleryImageIdByKey(dataset, outputDatasetName, key) {
  let outputDataset = dataset;

  if (outputDatasetName) {
    outputDataset = getOutputDatasetByName(dataset, outputDatasetName);
  }

  if (outputDataset) {
    const resourcesGalleryFilePaths = outputDataset.parameters.find(
      (p) => p.name === RESOURCES_GALLERY_FILE_PATHS_PARAMETER
    );
    if (resourcesGalleryFilePaths) {
      const index = resourcesGalleryFilePaths.value
        .toLowerCase()
        .split(',')
        .indexOf(
          resourcesGalleryFilePaths.value
            .toLowerCase()
            .split(',')
            .find(
              (path) => path.toLowerCase().indexOf(key.toLowerCase()) !== -1
            )
        );
      return outputDataset.parameters
        .find((p) => p.name === RESOURCES_GALLERY_PARAMETER)
        .value.split(' ')[index];
    }
  }
}
/**
 * This method returns the value of the output dataset parameter which output dataset name is outputDatasteName
 *
 * @param {*} dataset
 * @param {*} outputDatasetName Example: integrate
 * @param {*} name Example: SAXS_average_frames
 */
export function getOutputDatasetParameterValueByName(
  dataset,
  outputDatasetName,
  name
) {
  const outputDataset = getOutputDatasetByName(dataset, outputDatasetName);

  if (outputDataset) {
    const { parameters } = outputDataset;
    const sampleNameParameter = parameters.filter((o) => o.name === name);
    if (sampleNameParameter.length > 0) {
      const { value } = sampleNameParameter[0];
      return value;
    }
  }
  return '';
}

/**
 * This method returns the value of the dataset parameter with its units if is not NA otherwise it returns ''
 * @param {*} dataset
 * @param {*} name
 */
export function getDatasetUnitByName(dataset, name) {
  const { parameters } = dataset;
  const sampleNameParameter = parameters.filter((o) => o.name === name);
  if (sampleNameParameter.length > 0) {
    const { units } = sampleNameParameter[0];
    return units;
  }
  return '';
}

export function getDatasetParameterIdByName(dataset, name) {
  const sampleNameParameter = dataset.parameters.filter((o) => o.name === name);
  if (sampleNameParameter.length > 0) {
    return sampleNameParameter[0].id;
  }
}

/**
 * Returns a friendly readable string with the size
 * @param {integer} size Size in bytes
 */
export function stringifyBytesSize(size) {
  if (size === undefined) {
    return '';
  }

  for (const [i, unit] of BYTE_UNITS.entries()) {
    const multiplier = 1024 ** (BYTE_UNITS.length - i);

    if (size >= multiplier) {
      return `${(size / multiplier).toFixed(1)} ${unit}`;
    }
  }

  return `${size} Bytes`;
}

export function itemsFormatter(items) {
  const counts = items.reduce(
    (counts, { type }) => {
      counts[type] += 1;
      return counts;
    },
    { [ITEM_TYPES.SampleSheet]: 0, [ITEM_TYPES.Tool]: 0, [ITEM_TYPES.Other]: 0 }
  );

  return Object.entries(counts).map(([type, count]) => {
    const { countLabel } = ITEM_TYPE_DEFS[type];
    return `${count} ${count > 1 ? `${countLabel}s` : countLabel}`;
  });
}

export function getSamplePageURL(sample) {
  if (!sample.parameters) {
    return undefined;
  }

  const parameter = sample.parameters.find(
    (parameter) => parameter.name === 'Id'
  );
  if (!parameter) {
    return undefined;
  }

  return `${uiConfig.sample.pageTemplateURL}${parameter.value}`;
}

export function addAddressIfAbsent(addresses, addressToAdd) {
  if (
    !addressToAdd ||
    addresses.find((addr) => addr._id === addressToAdd._id)
  ) {
    return addresses;
  }

  return [addressToAdd, ...addresses];
}

export function makeFullScreen(img) {
  if (img.style.height && img.style.width) {
    // This test prevents bad image resizing out of fullscreen mode in case the user clicks several times on the image when it is in fullscreen mode.
    const originalHeightByStyle = img.style.height;
    const originalWidthByStyle = img.style.width;

    img.setAttribute('style', '');
    img.setAttribute('originalHeightByStyle', originalHeightByStyle);
    img.setAttribute('originalWidthByStyle', originalWidthByStyle);
  }

  img.onfullscreenchange = function handleFullscreenChange(event) {
    const img = event.target;
    const isFullscreen = document.fullscreenElement === img;

    if (!isFullscreen) {
      // img goes out of fullscreen mode
      img.style.height = img.getAttribute('originalHeightByStyle');
      img.style.width = img.getAttribute('originalWidthByStyle');
      img.style.cursor = 'zoom-in';
    }
  };

  img.onwebkitfullscreenchange = img.onfullscreenchange;
  img.onmozfullscreenchange = img.onfullscreenchange;
  img.onMSFullscreenChange = img.onfullscreenchange;

  try {
    if (img.requestFullscreen) {
      img.requestFullscreen();
    } else if (img.msRequestFullscreen) {
      img.msRequestFullscreen();
    } else if (img.mozRequestFullScreen) {
      img.mozRequestFullScreen();
    } else if (img.webkitRequestFullscreen) {
      img.webkitRequestFullscreen();
    } else {
      console.log('Fullscreen API is not supported');
    }
  } catch {
    console.log('make full screen not supported');
  }
}

export function hasSampleNameChanged(dataset) {
  const originalSampleName = getDatasetParameterByName(
    dataset,
    '__original_Sample_name'
  );
  return originalSampleName !== undefined;
}
