import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  INSTRUMENT_NAMES,
  INVESTIGATION_NAMES,
  TITLE,
} from '../../constants/parameterTypes';
import DOIBadge from '../doi/DOIBadge';
import ResponsiveTable from '../Table/ResponsiveTable';
import UI from '../../config/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { useResource } from 'rest-hooks';
import DataCollectionResource from '../../resources/datacollection';

function doiFormatter(cell) {
  return <DOIBadge doi={cell} />;
}

function titleFormatter(_, collection) {
  const title = collection.parameters.find((o) => o.name === TITLE);
  if (title && title.value) {
    return title.value;
  }
}

function nameFormatter(_, collection) {
  const names = collection.parameters.find(
    (o) => o.name === INVESTIGATION_NAMES
  );
  if (names && names.value) {
    let short = JSON.parse(JSON.stringify(names.value));

    if (!UI.investigationContainer.isDatasetListVisible) {
      return short;
    }

    if (short.length > 13) {
      short = `${short.slice(0, 10)}...`;
    }

    return (
      <Link to={`/public/${collection.doi}`}>
        <Button
          size="sm"
          style={{
            width: 120,
            textAlign: 'left',
            paddingBottom: 1,
            paddingTop: 1,
          }}
          variant="primary"
        >
          <FontAwesomeIcon icon={faArrowCircleRight} />
          <span style={{ marginLeft: 10 }}>{short} </span>
        </Button>
      </Link>
    );
  }
}

function beamlineFormatter(_, collection) {
  const names = collection.parameters.find((o) => o.name === INSTRUMENT_NAMES);
  if (names && names.value) {
    return names.value;
  }
}

function datasetCountFormmater(_, collection) {
  return collection.dataCollectionDatasets.length;
}

function dataCollectionFormatter(cell, collection, _, extraData) {
  return (
    <Container style={{ textAlign: 'center' }}>
      <Row className="show-grid">
        <Col xs={12} md={12}>
          {nameFormatter(cell, collection, extraData)}
        </Col>
      </Row>
      <Row className="show-grid">
        <Col xs={12} md={12}>
          {beamlineFormatter(cell, collection, extraData)}
        </Col>
      </Row>
      <Row className="show-grid">
        <Col xs={12}>
          <div style={{ color: 'gray', fontStyle: 'italic' }}>
            {' '}
            {titleFormatter(cell, collection, extraData)}
          </div>
        </Col>
      </Row>
      <Row className="show-grid" style={{ fontSize: 10 }}>
        <Col xs={12}>
          <span>{doiFormatter(collection.doi, collection, extraData)}</span>
        </Col>
      </Row>
    </Container>
  );
}

function appendParamsFieldsToData(data) {
  data = JSON.parse(JSON.stringify(data));
  data.forEach((dataEntry) => {
    dataEntry.parameters.forEach((param) => {
      dataEntry[param.name] = param.value;
    });
    dataEntry.nbDatasets = dataEntry.dataCollectionDatasets.length;
  });
  return data;
}
const columns = [
  {
    text: 'id',
    dataField: 'id',
    hidden: true,
  },
  {
    text: 'Experiment',
    dataField: 'name',
    formatter: dataCollectionFormatter,
    sort: true,
    hidden: false,
    headerStyle: () => ({ width: '50%', textAlign: 'center' }),
    responsiveHeaderStyle: {
      xs: { width: '100%' },
      sm: { width: '100%' },
      md: { hidden: true },
      lg: { hidden: true },
    },
  },
  {
    text: 'Proposal',
    dataField: 'investigationNames',
    formatter: nameFormatter,
    sort: true,
    hidden: false,
    headerStyle: () => ({ width: '50%', textAlign: 'center' }),
    responsiveHeaderStyle: {
      xs: { hidden: true },
      sm: { hidden: true },
      md: { width: 140, textAlign: 'center' },
      lg: { width: 140, textAlign: 'center' },
    },
  },
  {
    text: 'Beamline',
    dataField: 'instrumentNames',
    formatter: beamlineFormatter,
    sort: true,
    responsiveHeaderStyle: {
      xs: { hidden: true },
      sm: { hidden: true },
      md: { width: 140, textAlign: 'center' },
      lg: { width: 140, textAlign: 'center' },
    },
  },
  {
    text: 'Title',
    dataField: 'title',
    isDummyField: true,
    formatter: titleFormatter,
    sort: true,
    responsiveHeaderStyle: {
      xs: { hidden: true },
      sm: { hidden: true },
    },
  },
  {
    text: 'Datasets',
    dataField: 'nbDatasets',
    isDummyField: true,
    formatter: datasetCountFormmater,
    sort: true,
    responsiveHeaderStyle: {
      xs: { hidden: true },
      sm: { hidden: true },
      md: { width: 100, textAlign: 'center' },
      lg: { width: 100, textAlign: 'center' },
    },
  },

  {
    text: 'DOI',
    dataField: 'doi',
    formatter: doiFormatter,
    sort: true,
    responsiveHeaderStyle: {
      xs: { hidden: true },
      sm: { hidden: true },
      md: { hidden: true },
      lg: { width: 300, textAlign: 'center' },
    },
  },
];

function DataCollectionTable(props) {
  const { loadCollections } = props;
  const data = useResource(DataCollectionResource.listShape(), {});

  let documents = [];
  if (loadCollections) {
    documents = data?.filter((collection) =>
      collection.parameters.some(
        (p) => p.name === 'instrumentNames' && p.value !== 'PUBLISHER'
      )
    );
  } else {
    documents = data?.filter((collection) =>
      collection.parameters.some(
        (p) => p.name === 'instrumentNames' && p.value === 'PUBLISHER'
      )
    );
  }
  documents = appendParamsFieldsToData(documents);

  return (
    <ResponsiveTable
      data={documents}
      columns={columns}
      pageOptions={{
        sizePerPage: 25,
        showTotal: true,
        sizePerPageList: [
          { text: '25', value: 25 },
          { text: '50', value: 50 },
          { text: '100', value: 100 },
        ],
      }}
    />
  );
}

export default DataCollectionTable;
