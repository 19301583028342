import React from 'react';
import { Badge, Card, Tab, Tabs } from 'react-bootstrap';
import { FILE_COUNT } from '../../../../constants/parameterTypes';
import DatasetFileTree from '../../../File/DatasetFileTree';
import DatasetFooter from '../Generic/Footer';
import DatasetMetadataTab from '../Generic/DatasetMetadataTab';
import PaleoDatasetSummary from './PaleoDatasetSummary';

const getFilesTabTitle = (dataset) => {
  const fileCount = dataset.parameters.find((o) => o.name === FILE_COUNT);
  if (fileCount && fileCount.value) {
    return (
      <div>
        Files{' '}
        <Badge style={{ backgroundColor: '#bfbfbf' }}>{fileCount.value}</Badge>
      </div>
    );
  }
  return 'Files';
};

function PaleoDatasetWidget(props) {
  const { dataset, sessionId } = props;

  return (
    <Card>
      <Card.Body>
        <Tabs id="tabs">
          <Tab eventKey={1} title="Summary">
            <PaleoDatasetSummary dataset={dataset} sessionId={sessionId} />
          </Tab>

          <Tab eventKey={11} title={getFilesTabTitle(dataset)} mountOnEnter>
            <DatasetFileTree dataset={dataset} sessionId={sessionId}>
              {' '}
              &gt;
            </DatasetFileTree>
          </Tab>
          <Tab eventKey={10} title="Metadata List" className="pull-right">
            <DatasetMetadataTab dataset={dataset} />
          </Tab>
        </Tabs>
      </Card.Body>
      <Card.Footer>
        <DatasetFooter
          location={dataset.location}
          sessionId={sessionId}
          dataset={dataset}
        />
      </Card.Footer>
    </Card>
  );
}

export default PaleoDatasetWidget;
