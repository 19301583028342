import { Resource } from 'rest-hooks';
import ICATPLUS from '../config/icatPlus';
import { store } from '../store';
import AddressResource from './address';

export default class ShipmentResource extends Resource {
  _id = undefined;
  investigationId = '';
  investigationName = '';
  name = '';
  defaultReturnAddress = undefined;
  defaultShippingAddress = undefined;
  comments = '';

  pk() {
    return this._id?.toString();
  }

  static schema = {
    defaultReturnAddress: AddressResource.asSchema(),
    defaultShippingAddress: AddressResource.asSchema(),
  };

  static get key() {
    return 'ShipmentResource';
  }

  static url(params) {
    const { investigationId } = params;
    const { sessionId } = store.getState().user;
    return `${ICATPLUS.server}/tracking/${sessionId}/shipment?investigationId=${investigationId}`;
  }

  static listUrl(params) {
    const { investigationId } = params;
    const { sessionId } = store.getState().user;
    return `${ICATPLUS.server}/tracking/${sessionId}/shipment?investigationId=${investigationId}`;
  }
}
