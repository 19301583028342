import ICATPLUS from '../../config/icatPlus';
import { getURLParamsByDictionary } from '../../helpers/url';

export function getRestoreURLByDatasetId(sessionId, datasetIds) {
  const params = getURLParamsByDictionary({ datasetIds });
  return `${
    ICATPLUS.server
  }/ids/${sessionId}/datasets/restore?${params.toString()}`;
}

export function getDownloadURLByDatafileIds(sessionId, datafileIds) {
  const params = getURLParamsByDictionary({ datafileIds });
  return `${
    ICATPLUS.server
  }/ids/${sessionId}/data/download?${params.toString()}`;
}

export function getDownloadURLByDatasetIds(sessionId, datasetIds) {
  const params = getURLParamsByDictionary({ datasetIds });
  return `${
    ICATPLUS.server
  }/ids/${sessionId}/data/download?${params.toString()}`;
}

export function getDatasetStatus(sessionId, datasetIds) {
  const params = getURLParamsByDictionary({ datasetIds });
  return `${
    ICATPLUS.server
  }/ids/${sessionId}/datasets/status?${params.toString()}`;
}
