import React from 'react';
import {
  DATASET_COUNT,
  ACQUISITION_DATASET_VOLUME,
  PROCESSED_DATASET_VOLUME,
  VOLUME,
  ACQUISITION_DATASET_COUNT,
  PROCESSED_DATASET_COUNT,
  ARCHIVED_DATASET_COUNT,
  ACQUISITION_ARCHIVED_DATASET_COUNT,
  PROCESSED_ARCHIVED_DATASET_COUNT,
} from '../../constants/parameterTypes';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stringifyBytesSize } from '../../helpers';

const Statistics = (props) => {
  const { count, volume, text } = props;
  return (
    <>
      {text && <>{text} </>}
      {count}
      <small className={'text-muted'} style={{ marginLeft: '5px' }}>
        {volume !== undefined && volume !== 0 && stringifyBytesSize(volume)}
      </small>
    </>
  );
};

/**
 * If statistics are present then it displays the number of datasets with the volume
 * Besides it will show a popup on mouve over with the acquisition and processed statistics
 * @param {*} props
 */
function DatasetVolumeStatisticsColumn(props) {
  const { parameters } = props;

  if (parameters[DATASET_COUNT] === undefined) {
    return '';
  }

  if (
    !parameters[PROCESSED_DATASET_VOLUME] ||
    !parameters[ACQUISITION_DATASET_VOLUME]
  ) {
    return (
      <Statistics
        count={parameters[DATASET_COUNT]}
        volume={parameters[VOLUME]}
      ></Statistics>
    );
  }

  // display the statistics per dataset parameter if exist
  // example of parameter: __SAXS_experimentTypeDatasetCount = {"HPLC":9,"sampleChanger":164}
  // build an array of [{paramName, values:[{type, nbDatasets}]},
  // eg. [{paramName: "SAXS_experimentType", values:[{type:"HPLC", nbDatasets:9},{type:"sampleChanger", nbDatasets:164}]}
  const skippedParameters = [
    ACQUISITION_DATASET_COUNT,
    PROCESSED_DATASET_COUNT,
    DATASET_COUNT,
    ARCHIVED_DATASET_COUNT,
    ACQUISITION_ARCHIVED_DATASET_COUNT,
    PROCESSED_ARCHIVED_DATASET_COUNT,
  ];
  const statsPerDatasetParameters = [];
  const regex = new RegExp(/__(.*)DatasetCount/g);
  for (const [name, value] of Object.entries(parameters)) {
    if (name.match(regex) && !skippedParameters.includes(name)) {
      let clearName = name.replace('__', '').replace('DatasetCount', '');
      const acquisitionRegex = new RegExp(/(.*)Acquisition/g);
      if (clearName.match(acquisitionRegex)) {
        clearName = `Raw ${clearName.replace('Acquisition', '')}`;
      }
      const processedRegex = new RegExp(/(.*)Processed/g);
      if (clearName.match(processedRegex)) {
        clearName = `Processed ${clearName.replace('Processed', '')}`;
      }
      const obj = JSON.parse(value);
      const values = [];
      for (const [type, nbDatasets] of Object.entries(obj)) {
        values.push({ type, nbDatasets });
      }
      statsPerDatasetParameters.push({ paramName: clearName, values });
    }
  }

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={
        <Popover>
          <Popover.Header as="h3">
            <FontAwesomeIcon icon={faChartBar} style={{ marginRight: '5px' }} />
            Dataset Statistics
          </Popover.Header>
          <Popover.Body>
            <Statistics
              text={'Raw : '}
              count={parameters[ACQUISITION_DATASET_COUNT]}
              volume={parameters[ACQUISITION_DATASET_VOLUME]}
            ></Statistics>
            <br />
            <Statistics
              text={'Processed : '}
              count={parameters[PROCESSED_DATASET_COUNT]}
              volume={parameters[PROCESSED_DATASET_VOLUME]}
            ></Statistics>
            {statsPerDatasetParameters.length > 0 && (
              <div style={{ marginTop: 4 }}>
                {statsPerDatasetParameters.map((stat, i) => (
                  <>
                    <p
                      key={`param-${i}`}
                      style={{ margin: 0, fontWeight: 'bold' }}
                    >
                      {stat.paramName}
                    </p>
                    {stat.values.map((value, index) => (
                      <div style={{ marginLeft: 4 }}>
                        <Statistics
                          key={`stat-${index}`}
                          text={`${value.type} : `}
                          count={value.nbDatasets}
                        ></Statistics>
                        <br />
                      </div>
                    ))}
                  </>
                ))}
              </div>
            )}
          </Popover.Body>
        </Popover>
      }
    >
      <div style={{ cursor: 'pointer' }} className={'text-right text-info'}>
        <Statistics
          count={parameters[DATASET_COUNT]}
          volume={parameters[VOLUME]}
        ></Statistics>
      </div>
    </OverlayTrigger>
  );
}

export default DatasetVolumeStatisticsColumn;
