import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setBreadCrumbs } from '../../actions/breadcrumbs';
import { getDatasetsById } from '../../api/icat-plus/catalogue';
import { getDownloadURLByDatasetIds } from '../../api/icat-plus/ids';
import Loader from '../../components/Loader';
import DatasetTable from '../../components/DatasetTable/DatasetTable';
import { usePageTracking } from '../../helpers/hooks';
import { trackDownload } from '../../helpers/trackData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPlus } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';

function SelectionPage() {
  usePageTracking();
  const sessionId = useSelector((state) => state.user.sessionId);
  const selectedDatasets = useSelector((state) => state.selectedDatasets);
  const dispatch = useDispatch();

  const [initialSelectedDatasets] = useState(selectedDatasets); // so datasets don't disappear from table when deselected
  const [state, setState] = useState({ datasets: [], isFetching: false });
  useEffect(() => {
    dispatch(setBreadCrumbs([{ name: 'My Selection' }]));

    if (initialSelectedDatasets.length > 0) {
      setState({ datasets: [], isFetching: true });

      axios
        .get(getDatasetsById(sessionId, initialSelectedDatasets))
        .then(({ data }) => {
          setState({ datasets: data, isFetching: false });
        });
    }
  }, [initialSelectedDatasets, dispatch, sessionId]);

  const { datasets, isFetching: fetching } = state;
  const tooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;

  function download() {
    trackDownload('Dataset', initialSelectedDatasets);
  }

  return (
    <div className="app__inner">
      <ButtonToolbar>
        <OverlayTrigger
          placement="bottom"
          overlay={tooltip('Mint a DOI for all selected datasets')}
        >
          <LinkContainer to="/selection/mint">
            <Button
              size="sm"
              style={{ marginLeft: 5 }}
              variant="primary"
              disabled={selectedDatasets.length === 0}
            >
              {' '}
              <FontAwesomeIcon icon={faPlus} /> Mint a DOI
            </Button>
          </LinkContainer>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={tooltip('Download all selected datasets')}
        >
          <a
            onClick={(e) => download(e)}
            style={{ color: 'white' }}
            href={getDownloadURLByDatasetIds(
              sessionId,
              initialSelectedDatasets
            )}
          >
            <Button
              style={{ marginLeft: 5 }}
              variant="primary"
              size="sm"
              disabled={selectedDatasets.length === 0}
            >
              <FontAwesomeIcon icon={faDownload} />
              <span style={{ marginLeft: 5 }} />
              Download all
            </Button>
          </a>
        </OverlayTrigger>
      </ButtonToolbar>

      {fetching ? (
        <Loader message="Loading datasets..." spacedOut />
      ) : (
        <>
          <br />
          <DatasetTable
            remote={false}
            datasets={datasets}
            pageOptions={{
              showTotal: true,
              sizePerPageList: [
                { text: '25', value: 25 },
                { text: '50', value: 50 },
                { text: '100', value: 100 },
                { text: '1000', value: 1000 },
              ],
            }}
          />
        </>
      )}
    </div>
  );
}

export default SelectionPage;
