import React, { useRef, useEffect } from 'react';
import { Alert, CloseButton } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useQuery } from '../../helpers/hooks';
import ResponsiveTable from '../Table/ResponsiveTable';
import StatusFilterDropdown from './StatusFilterDropdown';
import styles from './ParcelTable.module.css';
import moment from 'moment';
import InvestigationDateFilter from '../Investigation/InvestigationDateFilter';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function getActionColumn(handleView) {
  return {
    text: '',
    dataField: 'actions',
    formatter: (_, parcel) => (
      <Link
        to={`/investigation/${parcel.investigationId}/parcel/${parcel._id}`}
        onClick={(evt) => {
          evt.preventDefault();
          handleView(parcel);
        }}
      >
        <FontAwesomeIcon icon={faEye} style={{ marginRight: 5 }} />
        <span className={styles.actionLabel}>View</span>
      </Link>
    ),
    responsiveHeaderStyle: {
      xs: { width: 40 },
      sm: { width: 40 },
      md: { width: 70 },
      lg: { width: 70 },
    },
  };
}

function ParcelTable(props) {
  const { parcels, columns, isInvestigationFilterEnabled } = props;

  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  const { state } = location;
  useEffect(() => {
    if (state && state.scrollPosition) {
      window.scrollTo(...state.scrollPosition);
    }
  });

  const latestQuery = useRef(); // work around stale query reference when changing search term
  latestQuery.current = query;

  const dateFilter = query.get('date')
    ? moment(query.get('date'), moment.HTML5_FMT.DATE)
    : undefined;
  const statusFilter = query.get('status');
  const searchFilter = query.get('q');
  const [sortField, sortOrder] = (query.get('sort') || '_id-desc').split('-');

  function handleStartDateChange(date) {
    const newQuery = new URLSearchParams(query);

    if (date) {
      newQuery.set('date', moment(date).format(moment.HTML5_FMT.DATE));
    } else {
      newQuery.delete('date');
    }

    history.replace(`${location.pathname}?${newQuery.toString()}`);
  }

  function handleStatusFilterChange(status) {
    const newQuery = new URLSearchParams(query);

    if (status) {
      newQuery.set('status', status);
    } else {
      newQuery.delete('status');
    }

    history.replace(`${location.pathname}?${newQuery.toString()}`);
  }

  function handleSearchFilterChange(search) {
    const newQuery = new URLSearchParams(query);

    if (search) {
      newQuery.set('q', search);
    } else {
      newQuery.delete('q');
    }

    history.replace(`${location.pathname}?${newQuery.toString()}`);
  }

  function handleView(parcel) {
    history.push(
      `/investigation/${parcel.investigationId}/parcel/${parcel._id}`,
      {
        back: {
          title: 'Back to list',
          pathname: location.pathname,
          search: latestQuery.current.toString(),
          scrollPosition: [window.scrollX, window.scrollY],
        },
      }
    );
  }

  function handleSort(field, order) {
    if (field !== sortField || order !== sortOrder) {
      const newQuery = new URLSearchParams(query);
      newQuery.set('sort', `${field}-${order}`);
      history.replace(`${location.pathname}?${newQuery.toString()}`);
    }
  }

  function handleStatusFilterClear() {
    const newQuery = new URLSearchParams(query);
    newQuery.delete('status');
    history.replace(`${location.pathname}?${newQuery.toString()}`);
  }

  function handleDateFilterClear() {
    const newQuery = new URLSearchParams(query);
    newQuery.delete('date');
    history.replace(`${location.pathname}?${newQuery.toString()}`);
  }

  function isParcelMatching(parcel) {
    const isStatusMatching = !statusFilter || parcel.status === statusFilter;

    const isDateMatching =
      !dateFilter ||
      !parcel.investigation ||
      dateFilter.isSame(parcel.investigation.startDate, 'day');

    return (
      isStatusMatching && isDateMatching
      //parcel.investigation &&
      // parcel.investigation.instrument
    );
  }

  const columnsWithHandles = [
    ...columns,
    getActionColumn(handleView),
  ].map((col) => (col.sort ? { ...col, onSort: handleSort } : col));

  return (
    <>
      {parcels.length > 0 ? (
        <div className={styles.wrapper}>
          <div className={styles.filters}>
            <div className={styles.filter}>
              <StatusFilterDropdown
                value={statusFilter}
                onSelect={handleStatusFilterChange}
              />
              <CloseButton
                data-bs-theme="dark"
                className="bg-dark p-2"
                style={{ marginLeft: 10 }}
                disabled={!statusFilter}
                onClick={handleStatusFilterClear}
              />
            </div>
            {isInvestigationFilterEnabled && (
              <InvestigationDateFilter
                rootClassName={styles.filter}
                value={dateFilter && dateFilter.toDate()}
                onDayChange={handleStartDateChange}
                onClear={handleDateFilterClear}
              />
            )}
          </div>
          <ResponsiveTable
            keyField="_id"
            defaultSorted={[{ dataField: sortField, order: sortOrder }]}
            data={parcels.filter(isParcelMatching)}
            columns={columnsWithHandles}
            search={{ defaultSearch: searchFilter, searchFormatted: true }}
            onSearch={handleSearchFilterChange}
            rowClasses={styles.clickable}
            pageOptions={{
              showTotal: true,
              sizePerPageList: [
                { text: '25', value: 25 },
                { text: '50', value: 50 },
                { text: '100', value: 100 },
              ],
            }}
          />
        </div>
      ) : (
        <Alert>No parcels to display.</Alert>
      )}
    </>
  );
}

export default ParcelTable;
