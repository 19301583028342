import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  getDatasetParameterValueByName,
  getDatasetParameterIdByName,
  getDatasetUnitByName,
} from '../../../../helpers';
import ParameterTableWidget from '../../../Instrument/ParameterTableWidget';
import GalleryDatasetThumbnail from '../../GalleryDatasetThumbnail';
import { useSelector } from 'react-redux';
import UI from '../../../../config/ui';

function getParametersByName(dataset, parametersDictionary) {
  return parametersDictionary.map((parameter) => {
    return {
      key: parameter.key,
      id: getDatasetParameterIdByName(dataset, parameter.key),
      name: parameter.name,
      unit: getDatasetUnitByName(dataset, parameter.key),
      value: parameter.value
        ? parameter.value
        : getDatasetParameterValueByName(dataset, parameter.key),
    };
  });
}

function PaleoDatasetSummary(props) {
  const { dataset } = props;
  const user = useSelector((state) => state.user);
  /** Wwhen administrator the metadata will be editable */
  const { isAdministrator } = user;
  const url = UI.projects.paleo.frontEndURL.replace('{datasetId}', dataset.id);
  return (
    <>
      <Container fluid style={{ margin: 20 }}>
        <Row>
          <Col xs={12} sm={12} md={3}>
            <GalleryDatasetThumbnail
              dataset={dataset}
              nameColumnWidth="25%"
              index={0}
            />
            <h2>
              <a target="_blank" rel="noopener noreferrer" href={url}>
                Paleontology database
              </a>
            </h2>
            <ParameterTableWidget
              datasetId={dataset.id}
              editable={false}
              header="DOI"
              striped={false}
              parameters={getParametersByName(dataset, [
                { name: 'Abstract', key: 'DOI_abstract' },
                { name: 'Title', key: 'DOI_title' },
                { name: 'Users', key: 'DOI_users' },
              ])}
            />
          </Col>
          <Col xs={12} md={3}>
            <ParameterTableWidget
              datasetId={dataset.id}
              editable={false}
              header="Sample"
              nameColumnWidth="25%"
              striped={false}
              parameters={getParametersByName(dataset, [
                {
                  name: 'Scientific domain',
                  key: 'SamplePaleo_scientific_domain',
                },
                { name: 'Country', key: 'SampleLocalisation_country' },
                { name: 'Localisation', key: 'SampleLocalisation_name' },
                {
                  name: 'Formation',
                  key: 'SamplePaleoGeologicalTime_formation',
                },
                { name: 'Era', key: 'SamplePaleoGeologicalTime_era' },
                { name: 'Period', key: 'SamplePaleoGeologicalTime_period' },
                { name: 'Epoch', key: 'SamplePaleoGeologicalTime_epoch' },
                { name: 'Clade 1', key: 'SamplePaleoClassification_clade1' },
                { name: 'Clade 2', key: 'SamplePaleoClassification_clade2' },
                { name: 'Clade 3', key: 'SamplePaleoClassification_clade3' },
                { name: 'Clade 4', key: 'SamplePaleoClassification_clade4' },
                { name: 'Clade 5', key: 'SamplePaleoClassification_clade5' },
                { name: 'Clade 6', key: 'SamplePaleoClassification_clade6' },
                { name: 'Species', key: 'SamplePaleoClassification_species' },
                {
                  name: 'Material type',
                  key: 'SamplePaleoClassification_material_type',
                },
              ])}
            />
            <ParameterTableWidget
              datasetId={dataset.id}
              editable={isAdministrator}
              nameColumnWidth="25%"
              striped={false}
              header="Associated publication"
              parameters={getParametersByName(dataset, [
                { name: 'DOI paper', key: 'ExternalReferencesPublication_doi' },
                { name: 'Ref.', key: 'ExternalReferencesPublication_endnote' },
              ])}
            />
          </Col>
          <Col xs={12} md={3}>
            <ParameterTableWidget
              datasetId={dataset.id}
              editable={false}
              header="Additional information"
              nameColumnWidth="25%"
              striped={false}
              parameters={getParametersByName(dataset, [
                { name: 'Keyword 1', key: 'Notes_note_03' },
                { name: 'Keyword 2', key: 'Notes_note_04' },
                { name: 'Comments', key: 'Notes_note_00' },
                {
                  name: 'Repository institution',
                  key: 'SamplePaleo_repository_institution',
                },
                {
                  name: 'Authors',
                  key: 'ExternalReferencesDatacollector_endnote',
                },
                { name: 'Segmentation', key: 'Notes_note_01' },
              ])}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PaleoDatasetSummary;
