import React from 'react';
import { Carousel, Row, Card, Button } from 'react-bootstrap';
import UI from '../../config/ui';

export default function WelcomeAnnoucements() {
  const annoucements = UI.welcomeAnnoucements;

  if (annoucements.length === 0) {
    return <></>;
  }

  return (
    <>
      <Row>
        <Carousel fade variant="dark">
          {annoucements.map(
            ({ img, alt, captionTitle, captionText }, index) => (
              <Carousel.Item
                key={`announcement-${index}`}
                style={{ textAlign: 'center' }}
              >
                <img className="w-50" src={img} alt={alt} />
                <Carousel.Caption>
                  <h3>{captionTitle}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: captionText,
                    }}
                  />
                </Carousel.Caption>
              </Carousel.Item>
            )
          )}
        </Carousel>
      </Row>
      {UI.welcomeNote.enabled && (
        <Row>
          <div
            className="d-flex align-items-center
                        justify-content-center"
          >
            <Card
              style={{ width: '24rem', margin: 10 }}
              border="primary"
              className="text-center"
            >
              <Card.Header>
                <h4>{UI.welcomeNote.title}</h4>
              </Card.Header>
              <Card.Body>
                <Card.Text>{UI.welcomeNote.body}</Card.Text>
                <Button
                  variant="success"
                  href={UI.welcomeNote.button.url}
                  target="_blank"
                  size="lg"
                >
                  {UI.welcomeNote.button.label}
                </Button>
              </Card.Body>
            </Card>
          </div>
        </Row>
      )}
    </>
  );
}
